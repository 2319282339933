import React, { useState } from "react";
import { Typography, Box, TextField, Button, Grid } from "@mui/material";
import contactImage from "../../assets/images/contact.png"

function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission (this will create a mailto link)
  const handleSubmit = (e) => {
    e.preventDefault();

    // Create the mailto link with form data
    const { name, email, message } = formData;
    const subject = encodeURIComponent(`Contact Form: ${name}`);
    const body = encodeURIComponent(
      `Name: ${name}\nEmail: ${email}\nMessage: ${message}`
    );
    const mailtoLink = `mailto:admin@gmail.com?subject=${subject}&body=${body}`;

    // Open the user's default email client with pre-filled details
    window.location.href = mailtoLink;

    // Optionally clear the form
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography
        textAlign="center"
        gutterBottom
        sx={{
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "2rem",
          color: "#035f8b",
        }}
      >
        Contact Us
      </Typography>
      <Grid container spacing={4} alignItems="center">
        {/* Contact Details */}
        <Grid item xs={12} md={6}>
        <Box
              sx={{
                textAlign: "center",
              }}
            >
              <img
                src={contactImage}
                alt="contact"
                style={{
                  width: "100%",
                  maxWidth: "400px",
                  borderRadius: "8px",
                }}
              />
            </Box>
        </Grid>

        {/* Contact Form */}
        <Grid item xs={12} md={6}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 400,
              margin: "0 auto",
            }}
          >
            <TextField
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={4}
              margin="normal"
              fullWidth
            />
            <Button
              variant="contained"
              type="submit"
              sx={{ marginTop: 2, background: "#035f8b" }}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Contact;
