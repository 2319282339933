import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { keyframes } from "@mui/system";
import Banner from "../../assets/images/banner.jpg";

// Keyframes for animations
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Hero = () => {
  return (
    <Box
      sx={{
        height: { xs: "60vh", sm: "70vh", md: "80vh" }, // Adjust height for all screens
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#fff",
        backgroundImage: `url(${Banner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        position: "relative",
      }}
    >
      {/* Subtle Gradient Overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)", // Dark overlay
          zIndex: 1,
        }}
      />

      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "relative",
          zIndex: 2, // Ensure content is above overlay
          padding: { xs: "1rem", sm: "2rem", md: "3rem" }, // Responsive padding
        }}
      >
        <Grid item xs={12}>
          <Box>
            {/* Main Heading */}
            <Typography
              variant="h3"
              component="h1"
              sx={{
                fontWeight: "bold",
                marginBottom: "1rem",
                fontSize: { xs: "2rem", sm: "3rem", md: "4rem" }, // Responsive font sizes
                animation: `${fadeIn} 1s ease-in-out`,
              }}
            >
              <span style={{ color: "#2175bf" }}>Sense</span>
              <span style={{ color: "rgb(33 216 239)" }}>Digital</span>
            </Typography>

            {/* Subheading */}
            <Typography
              variant="h6"
              sx={{
                marginBottom: "1.5rem",
                lineHeight: "1.6",
                color: "rgba(255, 255, 255, 0.85)",
                fontSize: { xs: "1rem", sm: "1.25rem", md: "1.5rem" }, // Responsive font size for subheading
                maxWidth: { xs: "90%", sm: "80%", md: "40%" }, // Adjust width dynamically
                animation: `${fadeIn} 1.2s ease-in-out`,
              }}
            >
              Empowering businesses with SAP BTP solutions to seamlessly integrate, automate, and transform digital operations.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Hero;
