import React from "react";
import Slider from "react-slick"; // For carousel functionality
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Typography, Box, Card, Avatar, CardContent } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ReactComponent as TeamLogo } from "../../assets/team/team.svg";
import Pic2 from "../../assets/team/mehran.jpg"
import Pic3 from "../../assets/team/azhar.jpeg"
import Pic4 from "../../assets/team/nayyar.jpeg"
import Pic5 from "../../assets/team/rafay.jpeg"

// Single Team Component
const Team = () => {
  const teamMembers = [
    { name: "Azhar Iqbal", role: "Integrations Cosultant", image: Pic3 },
    { name: "Mehran Khan", role: "SAPUI5 Developer", image: Pic2},
    { name: "Nayyar Paul", role: "Integrations Consultant", image: Pic4 },
    { name: "Rao Rafay", role: "ABAP Developer", image: Pic5 },
  ];

  // Custom previous arrow component
  const CustomPrevArrow = ({ onClick }) => (
    <ChevronLeftIcon
      style={{
        position: "absolute",
        left: "-20px", // Place outside the slider container
        top: "50%",
        transform: "translateY(-50%)",
        background: "#035f8b",
        borderRadius: "50%",
        color: "#ffffff",
        height: "40px",
        width: "40px",
        cursor: "pointer",
        zIndex: 10,
      }}
      onClick={onClick}
    />
  );

  // Custom next arrow component
  const CustomNextArrow = ({ onClick }) => (
    <ChevronRightIcon
      style={{
        position: "absolute",
        right: "-20px", // Place outside the slider container
        top: "50%",
        transform: "translateY(-50%)",
        background: "#035f8b",
        borderRadius: "50%",
        color: "#ffffff",
        height: "40px",
        width: "40px",
        cursor: "pointer",
        zIndex: 10,
      }}
      onClick={onClick}
    />
  );

  // Slider settings
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box sx={{ padding: 4, position: "relative", background: "#efefef" }}>
      <Typography
        textAlign="center"
        gutterBottom
        sx={{
          fontSize: "21px",
          fontWeight: "bold",
          marginBottom: "1rem",
          color: "#035f8b",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TeamLogo style={{ height: "50px", fill: "rgb(33 216 239)", marginRight: "10px" }} />  </Typography>

      <Box sx={{ position: "relative" }}> {/* Ensure proper arrow positioning */}
        <Slider {...settings}>
          {teamMembers.map((member, index) => (
            <Box key={index} sx={{ padding: "15px" }}>
            <div className="zoom-card"> {/* Add the class here */}
              <Card sx={{ boxShadow: 3, textAlign: "center", padding: 2 }}>
                <Avatar
                  src={member.image}
                  alt={member.name}
                  sx={{
                    width: 140,
                    height: 140,
                    margin: "0 auto",
                    marginBottom: 2,
                  }}
                />
                <CardContent>
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#035f8b", }}>
                    {member.name}
                  </Typography>
                  <Typography sx={{  color: "#035f8b",}}>{member.role}</Typography>
                </CardContent>
              </Card>
            </div>
          </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Team;
