import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Box, Drawer, List, ListItem, ListItemText, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from "../../assets/logo/4x/headerLogo.png"

const menuItems = [
  { text: 'Home', key: 'hero' },
  { text: 'About', key: 'about' },
  { text: 'Services', key: 'services' },
  // { text: 'Testimonials', key: 'testimonials' },
  // { text: 'Portfolio', key: 'portfolio' },
  // { text: 'Team', key: 'team' },
  { text: 'Contact', key: 'contact' },
];

export default function Header({ refs }) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleScroll = (key) => {
    setDrawerOpen(false); // Close drawer for mobile view
    refs[key]?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <AppBar
      position="sticky"
      style={{
        background: "#fff",
        color: "#032332",
        top: 0, // Ensure it sticks to the top
        zIndex: 1100, // Higher z-index to overlay content
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toolbar>
        {/* <Typography variant="h5" style={{ flexGrow: 1, fontWeight: "bold" }}>
          SenseDigital
        </Typography> */}
        <Box style={{ flexGrow: 1, fontWeight: "bold" }}>
        <img 
      src={Logo} 
      alt="SenseDigital Logo" 
      style={{ height: "50px", cursor: "pointer", width: "100px" }} 
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} 
    />

        </Box>
        
        <Box sx={{ display: { xs: 'none', md: 'block' } }}>
          {menuItems.map((item) => (
            <Button
              key={item.key}
              color="inherit"
              onClick={() => handleScroll(item.key)}
              sx={{
                marginRight: "20px",
                fontWeight: "bold",
                color: "#035f8b",
                '&:hover': { backgroundColor: '#035f8b', color: '#fff' },
              }}
            >
              {item.text}
            </Button>
          ))}
        </Box>
        <IconButton
          color="inherit"
          aria-label="menu"
          sx={{ display: { md: 'none' } }}
          onClick={toggleDrawer(true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          <List>
            {menuItems.map((item) => (
              <ListItem
                button
                key={item.key}
                onClick={() => handleScroll(item.key)}
              >
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
