import React from "react";
import Slider from "react-slick"; // Assuming you are using react-slick for the carousel functionality
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from 'framer-motion';
import {
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import { ReactComponent as ServicesLogo } from "../../assets/images/service.svg";
import Abap from "../../assets/services/abap.jpeg";
import Cpi from "../../assets/services/cpi.png";
import Sapui5 from "../../assets/services/sapui5.png";
import OutputManagement from "../../assets/services/outputManagement.png";
import SuccessFactors from "../../assets/services/successfactors.png";



// Single Services Component
const Services = () => {

  const cards = [
    {
      image: Cpi,
      title: "SAP Integrations",
      description: [
        "Connect cloud, on-premise, and third-party systems effortlessly.",
        "Automate workflows to enhance operational efficiency.",
        "Leverage API management for secure and scalable integration.",
        "Enable event-driven architecture for real-time data processing.",
      ],
      style:{
        height: "70px", // Ensure a consistent size
        width: "70px",
        borderRadius: "20%", // Make it circular
        marginBottom: "10px", // Add space between image and title
    }
    },
    {
      image: Sapui5,
      title: "SAP SAPUI5/Fiori",
      description: [
        "Design responsive, role-based applications for web and mobile.",
        "Enhance productivity with intuitive, user-friendly interfaces.",
        "Deliver visually interactive dashboards and analytics.",
        "Ensure seamless performance with mobile-first design principles.",
      ],
      style:{
        height: "70px", // Ensure a consistent size
        width: "90px",
        borderRadius: "20%", // Make it circular
        marginBottom: "10px", // Add space between image and title
    }
    },
    {
      image: Abap,
      title: "SAP ABAP",
      description: [
        "Develop custom reports and module enhancements tailored to your needs.",
        "Implement seamless third-party integrations for improved functionality.",
        "Optimize performance with scalable and efficient ABAP code.",
        "Extend existing SAP modules to align with evolving business goals.",
      ],
      style:{
        height: "70px", // Ensure a consistent size
        width: "90px",
        borderRadius: "20%", // Make it circular
        marginBottom: "10px", // Add space between image and title
    }
    },
    {
      image: OutputManagement,
      title: "SAP Output Controls",
      description: [
        "Create dynamic and customizable Smart Forms for various needs.",
        "Automate document generation, reducing manual effort.",
        "Ensure accuracy in invoices, delivery notes, and business reports.",
        "Streamline communication workflows for enhanced efficiency.",
      ],
      style:{
        height: "70px", // Ensure a consistent size
        width: "70px",
        borderRadius: "20%", // Make it circular
        marginBottom: "10px", // Add space between image and title
    }
    },
    {
      image: SuccessFactors,
      title: "SAP SuccessFactors",
      description: [
        "Manage HR processes like recruiting, onboarding, and performance.",
        "Intuitive interface for easy use by HR teams and employees.",
        "Helps make informed decisions with powerful analytics.",
        "Connects smoothly with SAP and third-party systems.",
      ],
        style:{
          height: "70px", // Ensure a consistent size
          width: "70px",
          borderRadius: "20%", // Make it circular
          marginBottom: "10px", // Add space between image and title
      }
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1880, settings: { slidesToShow: 4, slidesToScroll: 1 } },
      { breakpoint: 1280, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 960, settings: { slidesToShow: 3, slidesToScroll: 1 } },
      { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
  };

  return (
    <Box sx={{ padding: 4 }} style={{background: "#efefef"}}>
      {/* Icon Above the Title */}
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
        <ServicesLogo
          style={{
            width: "60px",
            height: "60px",
            fill: "rgb(33 216 239)",
          }}
        />
      </Box>

      {/* Title Section */}
      <Typography
        textAlign="center"
        gutterBottom
        style={{
          fontSize: "26px",
          fontWeight: "bold",
          marginBottom: "1rem",
          color: "#035f8b",
        }}
      >
        What We Offer for You
      </Typography>

      {/* Services Slider Section */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Slider {...settings}>
            {cards.map((item, index) => (
              <div key={index} style={{ margin: "7px 7px", padding: "7px" }}>
                   <motion.div
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.3 },
                    }}
                    whileInView={{ opacity: 1 }}
                    initial={{ opacity: 0 }}
                    transition={{ duration: 0.6 }}
                  >
            <Card
  sx={{
    backgroundColor: "#fff",
    color: "#035f8b",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
    transition: "background-color 0.3s, color 0.3s",
    height: "360px", // Set a consistent height
    display: "flex",
    flexDirection: "column", // Ensure proper alignment for content
    justifyContent: "space-between", // Space out content vertically
    "&:hover": {
      backgroundColor: "#035f8b",
      color: "#fff",
    },
  }}
>
  <CardContent sx={{ padding: "10px" }}>
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Stack the image, title, and description vertically
        justifyContent: "flex-start", // Align to the top
      }}
    >
      <img
        src={item.image}
        alt={item.title}
        style={item.style}
      />
      <Typography
        sx={{
          fontSize: { xs: "16px", md: "18px" },
          fontWeight: "bold", // Set the font weight
          whiteSpace: "nowrap", // Prevent wrapping
          maxWidth: "150px", // Set a maximum width for the title
          marginBottom: "10px", // Add space between title and description
        }}
      >
        {item.title}
      </Typography>
      <ul
        style={{
          margin: 0, // Remove default margin
          padding: 0, // Remove padding for the list
          listStyleType: "none", // Remove bullets from the list
          width: "100%", // Ensure the description list takes up full width
        }}
      >
        {item.description.map((desc, idx) => (
          <li
            key={idx}
            style={{
              lineHeight: "1.6",
              transition: "color 0.3s",
              marginBottom: "10px", // Add spacing between list items
              fontSize: "14px",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "12px", sm: "14px", md: "16px" }, // Responsive font size
              }}
            >
              {desc}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  </CardContent>
</Card>
                </motion.div>
              </div>
            ))}
          </Slider>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;
