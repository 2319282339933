import React from "react";
import { Box, Typography, Grid, List, ListItem, ListItemText } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';

const menuItems = [
  { text: "Home", key: "hero" },
  { text: "About", key: "about" },
  { text: "Services", key: "services" },
  { text: "Contact", key: "contact" },
];

const servicesList = [
  { text: "SAP Integrations", key: "services" },
  { text: "SAP SAPUI5/Fiori", key: "services" },
  { text: "SAP ABAP", key: "services" },
  { text: "SAP Output Controls", key: "services" },
  { text: "SAP SuccessFactors", key: "services" },
 
];

const Footer = ({ refs }) => {
  const handleScroll = (key) => {
    refs[key]?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <Box
      sx={{
        backgroundColor: "#035f8b",
        color: "#fff",
        padding: "20px 40px",
        marginTop: "auto",
      }}
    >
      <Grid container spacing={3}>
        {/* Company Logo Section */}
        <Grid item xs={12} md={3}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              SenseDigital
            </Typography>
          </Box>
          <Typography variant="body2" sx={{ marginTop: 1 }}>
            We are SAP experts focused on simplifying processes, integrating systems seamlessly, and delivering efficient solutions to drive better business results.
          </Typography>
        </Grid>

        {/* Contacts Section */}
        <Grid item xs={12} md={3}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PhoneIcon sx={{ marginRight: 1 }} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Contact Us
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", marginTop: "5px" }}>
            <EmailIcon sx={{ marginRight: 1 }} />
            <Typography>admin@sensedigital.com</Typography>
          </Box>
          <a
            href="https://www.linkedin.com/company/72686498/admin/dashboard/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedInIcon
              style={{
                marginLeft: "30px",
                marginTop: "10px",
                cursor: "pointer",
                height: "30px",
                width: "30px",
                background: "#fff",
                color: "#035f8b",
                borderRadius: "10%",
              }}
            />
          </a>
        </Grid>

        {/* Vertical Menu Section */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Quick Links
          </Typography>
          <List>
            {menuItems.map((item) => (
              <ListItem
                key={item.key}
                sx={{ padding: "2px 0", cursor: "pointer", marginTop: "-5px" }}
                onClick={() => handleScroll(item.key)}
              >
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Services Section */}
        <Grid item xs={12} md={2}>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            Services
          </Typography>
          <List>
            {servicesList.map((item, index) => (
              <ListItem
                key={index}
                sx={{ padding: "2px 0", cursor: "pointer", marginTop: "-5px" }}
                onClick={() => handleScroll(item.key)}
              >
                <ListItemText
                  primary={item.text}
                  primaryTypographyProps={{
                    color: "#fff",
                    fontSize: "16px",
                  }}
                 
                />
              </ListItem>
            ))}
          </List>
        </Grid>

        {/* Location Section */}
        <Grid item xs={12} md={2}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <LocationOnIcon sx={{ marginRight: 1 }} />
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              Address
            </Typography>
          </Box>
          <Typography variant="body2" ml={1} mt={1}>Suite H-03, First Floor, </Typography>
          <Typography variant="body2" ml={1}>14 East, A-Block, </Typography>
          <Typography variant="body2" ml={1}>Multi Garden B-17, Islamabad,</Typography>
          <Typography variant="body2" ml={1}>Pakistan</Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          textAlign: "center",
          borderTop: "1px solid #ccc",
          marginTop: "20px",
          paddingTop: "10px",
        }}
      >
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} SenseDigital. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
