import React from 'react';
import { motion } from 'framer-motion';
import { Box, Typography, Card, CardContent, Rating,  } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

// Sample Testimonials Data with Review Rating, Title, and Date
const testimonials = [
  {
    id: 1,
    title: "SAP SUPPORT FOR S4HANA MIGRATION",
    date: "AUG 4, 2023 - AUG 5, 2023",
    rating: 5.0,
    feedback: "We had an amazing experience with the SenseDigital team. They have SAP experts who helped us to resolve our S4HANA migration issues within no time. We would like to have them onboard in our S4HANA migration project as well. Great team and thanks for super support.",
  },
  {
    id: 2,
    title: "SYSTEM INTEGRATION FOR ERP PROJECT",
    date: "JUL 15, 2023 - JUL 20, 2023",
    rating: 4.5,
    feedback: "The team delivered on time and provided excellent support. The integration was smooth and the solution has improved our business processes. Looking forward to working together again in the future.",
  },
  {
    id: 3,
    title: "SUPPORT FOR CLOUD MIGRATION",
    date: "SEP 10, 2023 - SEP 12, 2023",
    rating: 4.8,
    feedback: "SenseDigital team has been a reliable partner in our cloud migration. They addressed all issues quickly, ensuring minimal downtime. Their expertise in cloud technologies was invaluable.",
  },
];

const TestimonialCard = ({ testimonial }) => {
  return (
    <motion.div
    whileHover={{
      scale: 1.05,
      transition: { duration: 0.3 },
    }}
    whileInView={{ opacity: 1 }}
    initial={{ opacity: 0 }}
    transition={{ duration: 0.6 }}
  >
    <Card
      sx={{
        maxWidth: 345,
        margin: "1rem",
        boxShadow: 3,
        borderRadius: 2,
        height: "300px",
        backgroundColor: "#fff", // Default background color
        color: "#035f8b", // Default text color
        transition: "background-color 0.3s, color 0.3s",
        "&:hover": {
          backgroundColor: "#035f8b", // Opposite hover background
          color: "#fff", // Opposite hover text color
        },
      }}
    >
      <CardContent>
        <Typography
          gutterBottom
          sx={{
            fontSize: "16px",
            fontWeight: "bold",
            transition: "color 0.3s",
            "&:hover": {
              color: "#fff", // Hover text color
            },
          }}
        >
          {testimonial.title}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "inherit", // Ensure it inherits the Card's color
            transition: "color 0.3s",
            "&:hover": {
              color: "#fff", // Hover text color
            },
          }}
        >
          {testimonial.date}
        </Typography>
        <Rating
          value={testimonial.rating}
          readOnly
          precision={0.1}
          sx={{ marginBottom: 1 }}
        />
        <Typography
          variant="body2"
          sx={{
            color: "inherit", // Ensure it inherits the Card's color
            transition: "color 0.3s",
            "&:hover": {
              color: "#fff", // Hover text color
            },
          }}
        >
          "{testimonial.feedback}"
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
);
};

const Testimonial = () => {
  return (
    <Box sx={{ padding: '2rem', textAlign: 'center' }}>
            <ThumbUpIcon sx={{ marginRight: 1, marginBottom: 1, width: '200px', height: '50px', color: "rgb(33 216 239)" }} />
      {/* Title Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 1 }}>
       
        <Typography variant="h5" sx={{ fontWeight: 'bold', color: "#035f8b", }}>
          Clients Testimonials
        </Typography>
      </Box>

      {/* Testimonials Cards Section */}
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        {testimonials.map((testimonial) => (
          <TestimonialCard key={testimonial.id} testimonial={testimonial} />
        ))}
      </Box>
    </Box>
  );
};

export default Testimonial;
