import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import RecommendIcon from "@mui/icons-material/Recommend";
import DiamondIcon from "@mui/icons-material/Diamond";
import VisionImage from "../../assets/images/visionImage.webp";
import MissionImage from "../../assets/images/missionImage.png";

function About() {
  return (
    <Box>
      {/* Intro Section */}
      <Box
        sx={{
          padding: "2rem",
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#035f8b",
            marginBottom: "1rem",
          }}
        >
          About Us
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "18px",
            lineHeight: 1.8,
            color: "#555",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
          SenseDigital is committed to driving digital transformation for
          businesses worldwide. Our expertise in SAP Business Technology
          Platform (BTP) enables us to design, build, and implement innovative
          solutions that address complex challenges. Whether it's empowering
          businesses with data-driven insights, fostering scalability, or
          ensuring seamless integrations, we are here to help organizations
          unlock their full potential.
        </Typography>
      </Box>

      {/* Vision and Mission Sections */}
      <Grid container spacing={4} alignItems="center">
        {/* Vision Section */}
        <Grid
          container
          item
          xs={12}
          md={6}
          direction={{ xs: "column", md: "row" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <img
                src={VisionImage}
                alt="Vision"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  color: "#035f8b",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <RecommendIcon
                  style={{
                    height: "30px",
                    width: "40px",
                    color: "rgb(33 216 239)",
                    fontSize: "28px",
                  }}
                />
                Vision
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1.6,
                  fontFamily: "Apercu",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                To empower businesses with innovative, scalable, and intelligent
                solutions leveraging the SAP Business Technology Platform (BTP).
                SenseDigital envisions becoming a trusted partner for digital
                transformation by delivering seamless integrations, data-driven
                insights, and robust cloud-native applications. We strive to
                enable organizations to unlock their full potential through
                cutting-edge technology and industry expertise, fostering
                agility, growth, and sustainability.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Mission Section */}
        <Grid
          container
          item
          xs={12}
          md={6}
          direction={{ xs: "column-reverse", md: "row" }}
          spacing={2}
        >
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: "#fff",
                padding: "2rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  marginBottom: "1rem",
                  color: "#035f8b",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DiamondIcon
                  style={{
                    height: "30px",
                    width: "40px",
                    fill: "rgb(33 216 239)",
                  }}
                />
                Mission
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1.6,
                  fontFamily: "Apercu",
                  fontSize: "17px",
                  textAlign: "justify",
                }}
              >
                At SenseDigital, our mission is to deliver transformative SAP
                BTP services that empower businesses to innovate and thrive in
                the digital age. We are dedicated to providing tailored
                solutions that streamline operations, enhance decision-making,
                and accelerate time-to-value. By leveraging the full potential
                of SAP Business Technology Platform, we aim to bridge the gap
                between technology and business needs, driving efficiency,
                scalability, and long-term success for our clients.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <img
                src={MissionImage}
                alt="Mission"
                style={{
                  width: "100%",
                  maxWidth: "300px",
                  borderRadius: "8px",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;
