import React, { useRef } from 'react';
import './App.css';
import { CssBaseline } from '@mui/material';
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Hero from './Components/Hero/Hero';
import Services from './Components/Services/Services';
// import Portfolio from './Components/Portfolio/Portfolio';
import Team from './Components/Team/Team';
import Footer from './Components/Footer/Footer';
import Testimonial from './Components/Testimonials/Testimonials';

function App() {
  const heroRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const testimonialsRef = useRef(null);
  const portfolioRef = useRef(null);
  const teamRef = useRef(null);
  const contactRef = useRef(null);

  const refs = {
    hero: heroRef,
    about: aboutRef,
    services: servicesRef,
    testimonials: testimonialsRef,
    portfolio: portfolioRef,
    team: teamRef,
    contact: contactRef,
  };

  return (
    <>
      <CssBaseline />
      <Header refs={refs} />
      <div ref={heroRef}>
        <Hero />
      </div>
      <div ref={aboutRef}>
        <About />
      </div>
      <div ref={servicesRef}>
        <Services ref={refs.services}  />
      </div>
      {/* <div ref={portfolioRef}>
        <Portfolio />
      </div> */}
       <div ref={testimonialsRef}>
        <Testimonial />
      </div>
      <div ref={teamRef}>
        <Team />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Footer refs={refs} />
     
    </>
  );
}

export default App;
